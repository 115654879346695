<script lang="ts" setup>
  import { GameHierarchyEntry, GameHierarchyId } from '~/model/GameHierarchy/GameHierarchy'
  import { computed } from '#imports'
  import useGameHierarchy from '~/composables/GameHierarchy/GameHierarchy'
  import UiIcon from '~/components/Ui/UiIcon/UiIcon.vue'
  import { UiIconId, UiIconSeriesId, UiIconSizeId } from '~/model/UiIcon/UiIcon'
  import useTranslation from '~/composables/Translation/Translation'
  import { VisualThemeId } from '~/model/VisualTheme/VisualTheme'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import useUiIcon from '~/composables/UiIcon/UiIcon'

  interface Props extends UseBemProps {
    facets?: Array<string>
    hierarchyId?: GameHierarchyId
    visualTheme?: VisualThemeId
  }

  const props = withDefaults(defineProps<Props>(), {
    visualTheme: VisualThemeId.Negative,
    hierarchyId: undefined,
  })
  const { bemAdd, bemFacets } = useBem('c-game-hierarchy-label', props, {})
  const { t } = useTranslation()
  const { gameHierarchyDict } = useGameHierarchy()
  const { findIcon } = useUiIcon()

  const rootClasses = computed<Array<string>>(() => {
    return [...bemFacets.value, bemAdd(props.visualTheme)]
  })

  const entry = computed<GameHierarchyEntry | undefined>(() => {
    if (!props.hierarchyId) {
      return undefined
    }

    return gameHierarchyDict[props.hierarchyId]
  })

  const iconId = computed<UiIconId | undefined>(() => {
    const series =
      props.visualTheme === VisualThemeId.Positive
        ? UiIconSeriesId.CoreWhitePos
        : UiIconSeriesId.CoreWhiteNeg

    return findIcon({ series, name: entry.value?.icon })
  })
</script>

<template>
  <div :class="rootClasses" class="c-game-hierarchy-label">
    <div class="c-game-hierarchy-label__icon">
      <slot name="icon">
        <UiIcon v-if="iconId" :id="iconId" :size="UiIconSizeId.Large" />
      </slot>
    </div>
    <span class="c-game-hierarchy-label__description">
      <slot name="label">
        {{ t(entry?.label) }}
      </slot>
    </span>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/base/typography/typography' as type;
  @use '/assets/scss/util/color/color' as col;

  .c-game-hierarchy-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }

  .c-game-hierarchy-label__icon {
    color: transparent;
  }

  .c-game-hierarchy-label__description {
    @include type.copy-small;
    padding: 4px 8px 3px;
  }
</style>
